<template>
  <!--begin::Wrapper-->
  <div class="w-100">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-15">
      <!--begin::Title-->
      <h2 class="fw-bolder d-flex align-items-center text-dark">
        Kullanıcı Hesap Türünü
        <i
            class="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title=""
        ></i>
      </h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div class="text-gray-400 fw-bold fs-6">
        Tanımlamayla ilgili daha fazla bilgiye ihtiyacınız varsa
        <a href="#" class="link-primary fw-bolder">Yardım Sayfası (Çok Yakında :)</a>
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <!--begin::Input group-->
    <div class="fv-row">
      <!--begin::Row-->
      <div class="row">
        <!--begin::Col-->
        <div class="col-lg-6">
          <!--begin::Option-->
          <Field
              type="radio"
              class="btn-check"
              name="accountType"
              value="Master"
              id="kt_create_account_form_account_type_personal"
          />
          <label
              class="
              btn btn-outline btn-outline-dashed btn-outline-default
              p-7
              d-flex
              align-items-center
              mb-10
            "
              for="kt_create_account_form_account_type_personal"
          >
            <span class="svg-icon svg-icon-3x me-5">
              <inline-svg src="media/icons/duotune/communication/com005.svg"/>
            </span>

            <!--begin::Info-->
            <span class="d-block fw-bold text-start">
              <span class="text-dark fw-bolder d-block fs-4 mb-2">
                Ana Şirket Kullanıcısı
              </span>
              <span class="text-gray-400 fw-bold fs-6"
              >Kullanıcı alt şirket bilgilerine erişebilir.</span
              >
            </span>
            <!--end::Info-->
          </label>
          <!--end::Option-->
        </div>
        <!--end::Col-->

        <!--begin::Col-->
        <div class="col-lg-6">
          <!--begin::Option-->
          <Field
              type="radio"
              class="btn-check"
              name="accountType"
              value="Company"
              id="kt_create_account_form_account_type_corporate"
          />
          <label
              class="
              btn btn-outline btn-outline-dashed btn-outline-default
              p-7
              d-flex
              align-items-center
            "
              for="kt_create_account_form_account_type_corporate"
          >
            <span class="svg-icon svg-icon-3x me-5">
              <inline-svg src="media/icons/duotune/finance/fin006.svg"/>
            </span>

            <!--begin::Info-->
            <span class="d-block fw-bold text-start">
              <span class="text-dark fw-bolder d-block fs-4 mb-2"
              >Şirket Hesabı</span
              >
              <span class="text-gray-400 fw-bold fs-6"
              >Kullanıcı kendi şirket bilgilerine erişebilir.</span
              >
            </span>
            <!--end::Info-->
          </label>
          <!--end::Option-->
        </div>
        <!--end::Col-->
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="accountType"></ErrorMessage>
          </div>
        </div>

      </div>
      <!--end::Row-->
    </div>
    <!--end::Input group-->

  </div>
  <!--end::Wrapper-->

</template>

<script lang="js">
import {defineComponent, ref} from "vue";
import {Field, ErrorMessage} from "vee-validate";

export default defineComponent({
  name: "step-1",
  components: {
    Field,
    ErrorMessage,
  },

});


</script>
