<template>
  <!--begin::Wrapper-->
  <div class="w-100">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-15">
      <!--begin::Title-->
      <h2 class="fw-bolder text-dark">Kişisel Bilgiler</h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div class="text-gray-400 fw-bold fs-6">
        Tanımlamayla ilgili daha fazla bilgiye ihtiyacınız varsa
        <a href="#" class="link-primary fw-bolder">Yardım Sayfası (Çok Yakında :)</a>
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <!--begin::Input group-->
    <div class="row mb-6">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label fw-bold fs-6">Avatar</label>
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8">
        <!--begin::Image input-->
        <div
            class="image-input image-input-outline"
            data-kt-image-input="true"
            style="background-image: url(media/avatars/blank.png)"
        >
          <!--begin::Preview existing avatar-->
          <div
              class="image-input-wrapper w-125px h-125px"
              :style="`background-image: url(${myAvatar})`"
          ></div>
          <!--end::Preview existing avatar-->

          <!--begin::Label-->
          <label
              class="
                    btn btn-icon btn-circle btn-active-color-primary
                    w-25px
                    h-25px
                    bg-white
                    shadow
                  "
              data-kt-image-input-action="change"
              data-bs-toggle="tooltip"
              title="Change avatar"
          >
            <i class="bi bi-pencil-fill fs-7"></i>

            <!--begin::Inputs-->

            <Field type="file" v-on:change="myMeth($event)" id="accountAvatarFile" name="accountAvatarFile" accept=".png, .jpg, .jpeg"/>
            <Field type="hidden" name="accountAvatar" v-model="myAvatarFile"/>
            <input type="hidden" name="avatar_remove"/>

            <!--end::Inputs-->
          </label>
          <!--end::Label-->

          <!--begin::Remove-->
          <span
              class="
                    btn btn-icon btn-circle btn-active-color-primary
                    w-25px
                    h-25px
                    bg-white
                    shadow
                  "
              data-kt-image-input-action="remove"
              data-bs-toggle="tooltip"
              @click="removeImage()"
              title="Remove avatar"
          >
                  <i class="bi bi-x fs-2"></i>
                </span>
          <!--end::Remove-->
        </div>
        <!--end::Image input-->

        <!--begin::Hint-->
        <div class="form-text">Allowed file types: png, jpg, jpeg.</div>
        <!--end::Hint-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label required fw-bold fs-6"
      >İsim / Soyisim</label
      >
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8">
        <!--begin::Row-->
        <div class="row">
          <!--begin::Col-->
          <div class="col-lg-6 fv-row">

            <Field
                type="text"
                v-model="myName"
                class="form-control form-control-lg form-control-solid"
                name="accountName"
                placeholder="İsim"
                value=""
            />

            <ErrorMessage
                name="accountName"
                class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>

          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-lg-6 fv-row">
            <Field
                type="text"
                v-model="myLastname"
                name="accountLastname"
                class="form-control form-control-lg form-control-solid"
                placeholder="Soyisim"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="accountLastname"/>
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->


    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label required fw-bold fs-6"
      >Username</label
      >
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8">
        <!--begin::Row-->
        <div class="row">
          <!--begin::Col-->
          <div class="col-lg-12 fv-row">

            <Field
                type="text"
                v-model="myUsername"
                class="form-control form-control-lg form-control-solid"
                name="accountUsername"
                placeholder="Kullanıcı Adı"
                value=""
            />

            <ErrorMessage
                name="accountUsername"
                class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>

          </div>
          <!--end::Col-->

        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->


    <!--begin::Input group-->
    <div class="row mb-6">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label fw-bold fs-6">
        <span class="required">Ülke</span>

        <i
            class="fas fa-exclamation-circle ms-1 fs-7"
            data-bs-toggle="tooltip"
            title="Country of origination"
        ></i>
      </label>
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-4 fv-row">
        <Field
            as="select"
            name="accountCountry"
            v-model="selectedCountry"
            class="form-select form-select-solid form-select-lg fw-bold"
        >
          <option value="TRNC">Kuzey Kıbrıs</option>
          <option value="TR">Türkiye</option>
        </Field>
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="accountCountry"/>
          </div>
        </div>
      </div>
      <!--end::Col-->

      <!--begin::Col-->
      <div class="col-lg-4 fv-row">
        <Field
            as="select"
            name="accountCity"
            class="form-select form-select-solid form-select-lg fw-bold"
        >
          <option value=""></option>
          <option v-for="( citiesItem, key ) in filteredCitiy" :value="citiesItem.Name" :key="key">{{ citiesItem.Name }}</option>
        </Field>
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="accountCity" />
          </div>
        </div>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mb-6">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label fw-bold fs-6">
        <span class="required">Dil</span>

        <i
            class="fas fa-exclamation-circle ms-1 fs-7"
            data-bs-toggle="tooltip"
            title="Country of origination"
        ></i>
      </label>
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8 fv-row">
        <Field
            as="select"
            name="accountLanguage"
            class="form-select form-select-solid form-select-lg fw-bold"
        >
          <option value="TR">Türkçe</option>
        </Field>
        <div class="fv-plugins-message-container">
          <div class="fv-help-block">
            <ErrorMessage name="accountLanguage"/>
          </div>
        </div>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label required fw-bold fs-6"
      >İletişim</label
      >
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8">
        <!--begin::Row-->
        <div class="row">
          <!--begin::Col-->
          <div class="col-lg-6 fv-row">

            <Field
                type="email"
                class="form-control form-control-lg form-control-solid"
                name="accountEmail"
                placeholder="E-mail"
                value=""
            />

            <ErrorMessage
                name="accountEmail"
                class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>

          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-lg-6 fv-row">
            <Field
                type="text"
                name="accountMobile"
                class="form-control form-control-lg form-control-solid"
                v-maska="['+90 (###) ##-##-##','+90 (###) ###-##-##']"
                placeholder="Mobile +90"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="accountMobile"/>
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->


    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label required fw-bold fs-6"
      >Şifre</label
      >
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8">
        <!--begin::Row-->
        <div class="row">
          <!--begin::Col-->
          <div class="col-lg-6 fv-row">

            <Field
                type="password"
                class="form-control form-control-lg form-control-solid"
                name="accountPassword"
                placeholder="Şifre"
                value=""
            />

            <ErrorMessage
                name="accountPassword"
                class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>

          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-lg-6 fv-row">
            <Field
                type="password"
                name="accountPassword2"
                class="form-control form-control-lg form-control-solid"
                placeholder="Onayla"
            />
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="accountPassword2"/>
              </div>
            </div>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->


    <!--begin::Input group-->
    <div class="mb-0 fv-row">
      <!--begin::Label-->
      <label class="d-flex align-items-center form-label mb-5">
        Hesap Planını Seçin
        <i
            class="fas fa-exclamation-circle ms-2 fs-7"
            data-bs-toggle="tooltip"
            title="Monthly billing will be based on your account plan"
        ></i>
      </label>
      <!--end::Label-->

      <!--begin::Options-->
      <div class="mb-0">

        <!--begin:Option-->
        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <!--begin:Label-->
          <span class="d-flex align-items-center me-2">
            <!--begin::Icon-->
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="media/icons/duotune/finance/fin001.svg"/>
                </span>
              </span>
            </span>
            <!--end::Icon-->

            <!--begin::Description-->
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
              >Kullanıcı Hesabı</span
              >
              <span class="fs-6 fw-bold text-gray-400"
              >Sistem kullanıcısı oluştur</span
              >
            </span>
            <!--end:Description-->
          </span>
          <!--end:Label-->

          <!--begin:Input-->
          <span class="form-check form-check-custom form-check-solid">
            <Field
                class="form-check-input"
                type="radio"
                name="accountPlan"
                value="1"
            />
          </span>
          <!--end:Input-->
        </label>
        <!--end::Option-->


        <!--begin:Option-->
        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <!--begin:Label-->
          <span class="d-flex align-items-center me-2">
            <!--begin::Icon-->
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="media/icons/duotune/graphs/gra006.svg"/>
                </span>
              </span>
            </span>
            <!--end::Icon-->

            <!--begin::Description-->
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
              >Developer Hesabı</span
              >
              <span class="fs-6 fw-bold text-gray-400"
              >Sistemi Developer modunda kullan</span
              >
            </span>
            <!--end:Description-->
          </span>
          <!--end:Label-->

          <!--begin:Input-->
          <span class="form-check form-check-custom form-check-solid">
            <Field
                class="form-check-input"
                type="radio"
                checked
                name="accountPlan"
                value="2"
            />
          </span>
          <!--end:Input-->
        </label>
        <!--end::Option-->


        <!--begin:Option-->
        <label class="d-flex flex-stack mb-0 cursor-pointer">
          <!--begin:Label-->
          <span class="d-flex align-items-center me-2">
            <!--begin::Icon-->
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="media/icons/duotune/graphs/gra008.svg"/>
                </span>
              </span>
            </span>
            <!--end::Icon-->

            <!--begin::Description-->
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
              >Test Hesabı</span
              >
              <span class="fs-6 fw-bold text-gray-400"
              >Sistemde testlerinizi yapın.</span
              >
            </span>
            <!--end:Description-->
          </span>
          <!--end:Label-->

          <!--begin:Input-->
          <span class="form-check form-check-custom form-check-solid">
            <Field
                class="form-check-input"
                type="radio"
                name="accountPlan"
                value="3"
            />
          </span>
          <!--end:Input-->
        </label>
        <!--end::Option-->
      </div>
      <!--end::Options-->
      <div class="fv-plugins-message-container">
        <div class="fv-help-block">
          <ErrorMessage name="accountPlan"/>
        </div>
      </div>
    </div>
    <!--end::Input group-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="js">
import {defineComponent} from "vue";
import {Field, ErrorMessage} from "vee-validate";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
  },
  data: function () {
    return {
      myName: "",
      myLastname: "",
      myAvatar : "media/avatars/blank.png",
      myAvatarFile : "media/avatars/blank.png",
      selectedCountry: "",
    }
  },
  props:{
    cityData: Object,
  },
  methods: {
    myMeth(e){
      const file = e.target.files[0];
      this.myAvatar = URL.createObjectURL(file);
      this.myAvatarFile = e.target.files[0].name;
      this.myFileUplaod();
      console.log(this.myAvatarFile);
    },

    removeImage () {
      this.myAvatar = "media/avatars/blank.png";
    },
    async myFileUplaod() {
      let formData = new FormData();
      formData.append("file", document.getElementById("accountAvatarFile").files[0]);
      await fetch('http://localhost/fup/put.php', {
        method: "POST",
        body: formData
      });
    }
  },
  computed: {
    myUsername(){
      return (this.myName + "." + this.myLastname).toLowerCase();
    },
    filteredCitiy: function () {
      return (this.cityData).filter(citiesItem =>
          (citiesItem.Country.toLowerCase()) == this.selectedCountry.toLowerCase()
      );
    },
  },
});
</script>
