
import {computed, defineComponent} from "vue";
import {Field, ErrorMessage} from "vee-validate";
import useCurrencyInput from "vue-currency-input";


export default defineComponent({
  name: "step-5",
  components: {
    Field,
    ErrorMessage,
  },
  data: function () {
    return {
      startDateChecker: "",
      myCur:"TRY",
      myInput:"",
      myFormat:"",
    }
  },
  methods: {
    curChange(){
      this.options.currency= this.myCur;
      /*
      this.myInput = this.inputRef;
      const {formattedValue, inputRef} = useCurrencyInput(
          this.options
      );

       */
      //alert(this.options.currency);
    },
  },
  setup() {
    let options = {
      locale: 'de-DE',
      currency: 'TRY',

    };
    const {formattedValue, inputRef} = useCurrencyInput(
        options
    );

    return {inputRef, formattedValue, options};
  },
});
