
import {computed, defineComponent, onMounted, ref} from "vue";
import SrcPackage from "@/components/reservations/new/SrcPackage.vue";

import Step1 from "@/components/user/new/Step1.vue";
import Step2 from "@/components/user/new/Step2.vue";
import Step3 from "@/components/user/new/Step3.vue";
import Step4 from "@/components/user/new/Step4.vue";
import Step5 from "@/components/user/new/Step5.vue";
import Step6 from "@/components/user/new/Step6.vue"
import {StepperComponent} from "@/assets/ts/components";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import {useForm} from "vee-validate";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {useRouter} from "vue-router";
import {dateConvert} from "@/core/filters/datatime";

interface IStep1 {
    accountType: string;
}

interface IStep2 {
    accountName: string;
    accountLastname: string;
    accountUsername: string;
    accountCountry: string;
    accountCity: string;
    accountLanguage: string;
    accountEmail: string;
    accountMobile: string;
    accountPassword: string;
    accountPlan: string;
    accountAvatar: string;
    accountAvatarFile: any;
}

interface IStep3 {
    businessMaster: string;
    businessCompany: string;
    businessBranch: string;
    businessDepartment: string;
    businessTitle: string;
}

interface IStep4 {
    systemUserGroup: string;
}

interface IStep5 {
    workShiftType: string;
    workStartDate: string;
    workWage: string;
    workWageCur: string;
    workWageType: string;
    workIban: string;
}

interface CreateAccount extends IStep1, IStep2, IStep3, IStep4, IStep5 {
}

export default defineComponent({
    name: "kt-vertical-wizard",
    components: {
        SrcPackage,
        Step1,
        Step2,
        Step3,
        Step4,
        Step5,
        Step6,
    },
    data: function () {
        return {
            myAccType: "",
        }
    },
    setup() {
        const router = useRouter();
        const _stepperObj = ref<StepperComponent | null>(null);
        const verticalWizardRef = ref<HTMLElement | null>(null);
        const currentStepIndex = ref(0);

        const formData = ref<CreateAccount>({
            accountType: "",
            accountName: "",
            accountLastname: "",
            accountUsername: "",
            accountCountry: "",
            accountCity: "",
            accountLanguage: "",
            accountEmail: "",
            accountMobile: "",
            accountPassword: "",
            accountPlan: "",
            accountAvatar: "",
            accountAvatarFile: [],
            businessMaster: "",
            businessCompany: "",
            businessBranch: "",
            businessDepartment: "",
            businessTitle: "",
            systemUserGroup: "",
            workShiftType: "",
            workStartDate: "",
            workWage: "",
            workWageCur: "",
            workWageType: "",
            workIban: "",
        });

        onMounted(() => {
            _stepperObj.value = StepperComponent.createInsance(
                verticalWizardRef.value as HTMLElement
            );

            setCurrentPageBreadcrumbs("Yeni Kullanıcı", ["Kullanıcılar", "Ekle"]);
        });

        const createAccountSchema = [
            Yup.object({
                accountType: Yup.string().required().label("Account Type"),
            }),
            Yup.object({
                accountName: Yup.string().required().label("İsim"),
                accountLastname: Yup.string().required().label("Soyisim"),
                accountUsername: Yup.string().required().label("Kullancı Adı"),
                accountCountry: Yup.string().required().label("Ülke"),
                accountCity: Yup.string().required().label("Şehir"),
                accountLanguage: Yup.string().required().label("Dil"),
                accountEmail: Yup.string().required().email().label("Email"),
                accountMobile: Yup.string().required().label("Mobil Telefon"),
                accountPlan: Yup.string().required().label("Hesap Planı"),
                accountPassword: Yup.string().label("Plable").matches(
                    /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
                    "Şifre en az 8 karakter ve büyük harf - küçük harf - numara  ve özel karakter('@',# vs) içermelidir."
                ),
                accountPassword2: Yup.string()
                    .oneOf([Yup.ref('accountPassword'), null], 'Girilen şifreler aynı değil')
            }),
            Yup.object({
                businessMaster: Yup.string().required().label("Ana Şiket"),

                businessCompany: Yup.string().when("accountType", {
                    is: 'Master',
                    then: Yup.string().required("Bu ALan Mecburi..")
                }).label("Şiket"),

                businessBranch: Yup.string().required().label("Şube"),
                businessDepartment: Yup.string().required().label("Departman"),
                businessTitle: Yup.string().required().label("Title"),
            }),
            Yup.object({
                systemUserGroup: Yup.string().required().label("Kullanıcı Gurubu"),
            }),
            Yup.object({
                workShiftType: Yup.string().required().label("Mesai Türü"),
                //workStartDate: Yup.date().required(),
                workStartDate: Yup.string().required().matches(/^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/, "Geçersiz tarih formatı"),
                workWage: Yup.string().required().label("Maaş"),
                workWageCur: Yup.string().required().label("Maaş Kuru"),
                workWageType: Yup.string().required().label("Hesaplama Türü"),
            }),

        ];

        const currentSchema = computed(() => {
            return createAccountSchema[currentStepIndex.value];
        });

        const {resetForm, handleSubmit} = useForm<IStep1 | IStep2 | IStep3 | IStep4 | IStep5>({
            validationSchema: currentSchema,
        });

        const totalSteps = computed(() => {
            if (!_stepperObj.value) {
                return;
            }

            return _stepperObj.value.totatStepsNumber;
        });

        resetForm({
            values: {
                ...formData.value,
            },
        });

        const handleStep = handleSubmit((values) => {
            formData.value = {
                ...formData.value,
                ...values,
            };
            console.log(values);
            currentStepIndex.value++;
            console.log("CUR: " + currentStepIndex.value + " TOTAL" + totalSteps.value)

            if (!_stepperObj.value) {
                return;
            }

            _stepperObj.value.goNext();
        });

        const previousStep = () => {
            if (!_stepperObj.value) {
                return;
            }

            currentStepIndex.value--;

            _stepperObj.value.goPrev();
        };

        const formSubmit = () => {
            console.log(formData.value);


            let insertData = {
                Status: 'MASTER',
                Username: formData.value.accountUsername,
                Password: formData.value.accountPassword,
                Name: formData.value.accountName,
                Lastname: formData.value.accountLastname,
                CompanyID: formData.value.businessCompany,
                BranchID: formData.value.businessBranch,
                City: formData.value.accountCity,
                TitleID: formData.value.businessTitle,
                DepartmentID: formData.value.businessDepartment,
                AccountID: `1`,
                UserGroupID: formData.value.systemUserGroup,
                Avatar: formData.value.accountAvatar,
                Email: formData.value.accountEmail,
                Mobile: formData.value.accountMobile,
                Country: formData.value.accountCountry,
                Language: formData.value.accountLanguage,
                Type: formData.value.accountPlan,
                BankAccount: formData.value.workIban,
                ShiftType: formData.value.workShiftType,
                Wage: formData.value.workWage,
                WageCur: formData.value.workWageCur,
                WageType: formData.value.workWageType,
                WageStartDate: formData.value.workStartDate,
                WageDay: '7',
                StartDate: (dateConvert.method.convertToDateYM(formData.value.workStartDate)),

            };
            Swal.fire({
                title: 'Emin misin.?',
                text: "Yeni Kullanıcı kayıt işlemini tamamlamak istediğine emin misin.?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet, Tamamla.!'
            }).then((result) => {
                if (result.isConfirmed) {
                    store.dispatch(Actions.USER_INSERT, insertData)
                        .then(() => {
                            Swal.fire({
                                text: "Tebrikler. Başarılı bir şekilde kayıt işlemini tamamladınız.",
                                icon: "success",
                                buttonsStyling: false,
                                confirmButtonText: "Listeye Dön!",
                                customClass: {
                                    confirmButton: "btn fw-bold btn-light-primary",
                                },
                            }).then(function () {
                                // Go to page after successfully login
                                //router.push({name: "userlist"});
                            });
                        })
                        .catch(() => {
                            Swal.fire({
                                text: store.getters.getUserErrors[0],
                                icon: "error",
                                buttonsStyling: false,
                                confirmButtonText: "Tekrar dene!",
                                customClass: {
                                    confirmButton: "btn fw-bold btn-light-danger",
                                },
                            });
                        });
                }
            })
        };

        store.dispatch(Actions.COMPANY_LIST, {});
        store.dispatch(Actions.CITY_LIST, {});
        store.dispatch(Actions.COMPANY_BRANCHES_LIST, {});
        store.dispatch(Actions.COMPANY_DEPARTMENTS_LIST, {});
        store.dispatch(Actions.COMPANY_TITLES_LIST, {});
        store.dispatch(Actions.SYSTEM_USER_GROUP_LISTALL, {});

        const myCompanies = computed(() => {
            return store.getters.getCompanies;
        });
        const myCities = computed(() => {
            return store.getters.CityList;
        });
        const myBranches = computed(() => {
            return store.getters.BranchList;
        });
        const myDepartments = computed(() => {
            return store.getters.DepartmentsList;
        });
        const myTitles = computed(() => {
            return store.getters.TitleList;
        });
        const mySystemUserGroup = computed(() => {
            return store.getters.SystemUserGroupList;
        });

        ////////////////////////********************/////////////////////////
        //store.dispatch(Actions.AIRPORTS_LIST_ALL, {});
        ////////////////////////********************/////////////////////////
        return {
            verticalWizardRef,
            previousStep,
            handleStep,
            formSubmit,
            totalSteps,
            currentStepIndex,
            formData,
            myCompanies,
            myCities,
            myBranches,
            myDepartments,
            myTitles,
            mySystemUserGroup,
        };
    },
});
