<template>
  <!--begin::Wrapper-->
  <div class="w-100">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-12">
      <!--begin::Title-->
      <h2 class="fw-bolder text-dark">Pozisyon Detayları</h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div class="text-gray-400 fw-bold fs-6">
        Tanımlamayla ilgili daha fazla bilgiye ihtiyacınız varsa
        <a href="#" class="link-primary fw-bolder">Yardım Sayfası (Çok Yakında :)</a>
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label required fw-bold fs-6"
      >Şirket / Alt Şirket</label
      >
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8">
        <!--begin::Row-->
        <div class="row">
          <!--begin::Col-->
          <div class="col-lg-6 fv-row">

            <Field
                as="select"
                name="businessMaster"
                class="form-select form-select-solid form-select-lg fw-bold"
            >
              <option  v-for="(parentComp, key) in parentCompanies" :key="key" :value="parentComp.ID" >{{ parentComp.Title }}</option>
            </Field>
            <ErrorMessage
                name="businessMaster"
                class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>

          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-lg-6 fv-row">
            <Field
                as="select"
                name="businessCompany"
                class="form-select form-select-solid form-select-lg fw-bold"
            >
              <option  v-for="(childComp, key) in childCompanies" :key="key" :value="childComp.ID" >{{ childComp.Title }}</option>
            </Field>
            <ErrorMessage
                name="businessCompany"
                class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->


    <!--begin::Input group-->
    <div class="row mb-6">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label fw-bold fs-6">
        <span class="required">Şube</span>

        <i
            class="fas fa-exclamation-circle ms-1 fs-7"
            data-bs-toggle="tooltip"
            title="Country of origination"
        ></i>
      </label>
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8 fv-row">
        <Field
            as="select"
            name="businessBranch"
            class="form-select form-select-solid form-select-lg fw-bold"
        >
          <option v-for="(item, key) in brncData" :key="key" :value="item.ID">{{ item.Title }}</option>
        </Field>
        <ErrorMessage
            name="businessBranch"
            class="fv-plugins-message-container invalid-feedback"
        ></ErrorMessage>
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

    <!--begin::Input group-->
    <div class="row mb-10">
      <!--begin::Label-->
      <label class="col-lg-4 col-form-label required fw-bold fs-6"
      >Departman / Pozisyon</label
      >
      <!--end::Label-->

      <!--begin::Col-->
      <div class="col-lg-8">
        <!--begin::Row-->
        <div class="row">
          <!--begin::Col-->
          <div class="col-lg-6 fv-row">

            <Field
                as="select"
                name="businessDepartment"
                v-model="selectedDepartment"
                class="form-select form-select-solid form-select-lg fw-bold"
            >
              <option v-for="(item, key) in depData" :key="key" :value="item.ID">{{ item.Title }}</option>
            </Field>
            <ErrorMessage
                name="businessDepartment"
                class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>

          </div>
          <!--end::Col-->

          <!--begin::Col-->
          <div class="col-lg-6 fv-row">
            <Field
                as="select"
                name="businessTitle"
                class="form-select form-select-solid form-select-lg fw-bold"
            >
              <option></option>
              <option v-for="(title, key) in filterTitle" :key="key" :value="title.ID">{{ title.Title }}</option>
            </Field>
            <ErrorMessage
                name="businessTitle"
                class="fv-plugins-message-container invalid-feedback"
            ></ErrorMessage>
          </div>
          <!--end::Col-->
        </div>
        <!--end::Row-->
      </div>
      <!--end::Col-->
    </div>
    <!--end::Input group-->

  </div>
  <!--end::Wrapper-->
</template>

<script lang="js">
import {computed, defineComponent} from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-3",
  components: {
    Field,
    ErrorMessage,
  },
  data: function () {
    return {
      myList : [],
      selectedDepartment: "",
    }
  },
  props:{
    compData: Object,
    brncData: Object,
    depData: Object,
    titleData: Object,
  },
  computed: {
    parentCompanies: function () {
      return (this.compData).filter(parentComp =>
          !(parentComp.ParentID) > 0
      );
    },
    childCompanies: function () {
      return (this.compData).filter(childComp =>
          (childComp.ParentID) > 0
      );
    },
    filterTitle: function () {
      return (this.titleData).filter(title =>
          (title.DepartmentID) == this.selectedDepartment
      );
    }
  },
setup(){
  return {

  };
}
});
</script>
