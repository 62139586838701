
import store from "@/store";
import {computed} from "vue";
import { Field} from "vee-validate";
import {Actions} from "@/store/enums/StoreEnums";
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.css';
import {Turkish} from "flatpickr/dist/l10n/tr"
import MasterConfig from "@/core/config/MasterConfig";

export default {
    name: "SrcPackage",
    components: {
        Field,

    },
    mounted() {
        this.setDatePicker();
        return true;
    },
    setup() {
        const setDatePicker = () => {
            let myConfigFrom = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                //defaultDate: new Date().fp_incr(2),
                minDate: "today",
            };

            let myConfigReturn = {
                "locale": Turkish,
                dateFormat: "d-m-Y (D)",
                //defaultDate: new Date().fp_incr(5),
                //minDate: new Date().fp_incr(1),
            };

            let tmpFromDate = flatpickr("#srcFromDate", myConfigFrom);
            let tmpReturnDate = flatpickr("#srcReturnDate", myConfigReturn);
        }

        const myAccountList = computed(() => {
            return store.getters.accountList;
        });
        const myAccountsTitle = computed(() => {
            let tmpArray = [];
            if (myAccountList.value.data !== undefined) {
                for (var i of myAccountList.value.data) {
                    tmpArray.push(i.Title);
                }
            }
            return tmpArray;
        });


        const myAccountProductList = computed(() => {
            return store.getters.accountProductList;
        });


        const myAirportList = computed(() => {
            return store.getters.airportsList;
        });
        const myAirportsTitle = computed(() => {
            let tmpArray = [];
            if (myAirportList.value.data !== undefined) {
                for (var i of myAirportList.value.data) {
                    tmpArray.push("(" + i.IATACode + ") - " + i.Title + " - " + i.City);
                }
            }
            return tmpArray;
        });

        const myToList = computed(() => {
            return store.getters.hotelList;
        });

        const myToTitle = computed(() => {
            let tmpArray = [];
            if (myToList.value.data !== undefined) {
                for (var i of myToList.value.data) {
                    tmpArray.push(i.Title);
                }
            }
            return tmpArray;
        });

        const getAccounts = () => {
            let myAccounts = document.getElementById("srcAccount") as HTMLInputElement;
            let myPayload = {};
            if (myAccounts.value.length == 3) {
                myPayload = {
                    'Title': myAccounts.value
                }
                store.dispatch(Actions.ACCOUNT_LIST_ALL, myPayload);
            }
        }

        const getAirports = () => {
            let myFrom = document.getElementById("srcFrom") as HTMLInputElement;
            let myPayload = {};
            if (myFrom.value.length == 3) {
                myPayload = {'IATACode': myFrom.value.toUpperCase()}
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            } else if (myFrom.value.length == 4) {
                myPayload = {'Title': myFrom.value}
                store.dispatch(Actions.AIRPORTS_LIST_ALL, myPayload);
            }
        }

        const getTo = () => {
            let myTo = document.getElementById("srcTo") as HTMLInputElement;
            let myPayload = {};
            if (myTo.value.length == 3) {
                myPayload = {
                    'Title': myTo.value
                }
                store.dispatch(Actions.HOTEL_LIST_ALL, myPayload);
            }
        }

        const getAccountProducts = (e) => {
            let myAccount = document.getElementById("srcAccount") as HTMLInputElement;
            let myPayload = {};
            myPayload = {
                'AccountID': filterAccounts(e)[0].ID,
                'Product': 'HOTEL',
            }
            store.dispatch(Actions.ACCOUNT_PRODUCT_LIST_ALL, myPayload);
        }

        const filterAccounts = (val) => {
            if (myAccountList.value.data != null) {
                return (myAccountList.value.data).filter(Items =>
                    (Items.Title == val)
                );
            } else {
                return true;
            }
        };
        return {
            getAccountProducts,
            MasterConfig,
            getAccounts,
            getTo,
            getAirports,
            myAccountsTitle,
            myAirportsTitle,
            myToTitle,
            myAccountProductList,
            setDatePicker,
        };
    }
}
